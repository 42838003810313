import './index.css';

function BlurBG() {
  return (
    <>
      <div className="blur-bg left"></div>
      <div className="blur-bg right"></div>
    </>
  );
}

export default BlurBG;
